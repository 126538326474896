import {
  buildInvoiceArPrepaymentLineRequestDto,
  buildTruckingAccountReceivableFormPrepaymentLineState,
  buildTruckingAccountReceivableFormPrepaymentState,
  buildTruckingAccountReceivableFormState,
  buildTruckingAccountReceivableFormStatusDetailPrepaymentState,
  buildTruckingAccountReceivableFormStatusDetailState,
  buildTruckingAccountReceivableProductState,
  buildTruckingInvoiceArCreateDto,
  buildTruckingInvoiceArLineRequestDto,
  buildTruckingInvoiceArUpdateDto,
} from "@/builder";
import { generateUUID } from "@/helpers/uuid";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { TruckingAccountReceivableUtils } from "@/utils";
import {
  InvoiceArCreditLineResponseDto,
  InvoiceArLineResponseDto,
  InvoiceArPrepaymentLineRequestDto,
  InvoiceArPrepaymentLineResponseDto,
  InvoiceArPrepaymentResponseDto,
  InvoiceArReceiptDetailDto,
  InvoiceArResponseDto,
} from "@interface/account-receivable";
import { InvoicePrepaymentListResponseDto } from "@interface/ar-prepayment";
import {
  SalesOrderLineResponseDto,
  SalesOrderResponseDto,
} from "@interface/sales-order";
import {
  TruckingAccountReceivableFormPrepaymentLineState,
  TruckingAccountReceivableFormPrepaymentState,
  TruckingAccountReceivableFormState,
  TruckingAccountReceivableFormStatusDetailInvoiceState,
  TruckingAccountReceivableFormStatusDetailState,
  TruckingAccountReceivableProductState,
  TruckingInvoiceArCreateDto,
  TruckingInvoiceArLineRequestDto,
  TruckingInvoiceArLineResponseDto,
  TruckingInvoiceArResponseDto,
  TruckingInvoiceArUpdateDto,
} from "@interface/trucking-account-receivable";
import moment from "moment";

const isPrepaymentLineResponseDto = (
  data: any
): data is InvoiceArPrepaymentLineResponseDto => {
  return typeof data === "object" && "invoicePrepaymentId" in data;
};

const isArCreditLineResponseDto = (
  data: any
): data is InvoiceArCreditLineResponseDto => {
  return typeof data === "object" && "invoiceCreditId" in data;
};

const isArReceiptDetailDto = (data: any): data is InvoiceArReceiptDetailDto => {
  return typeof data === "object" && "receiptNumber" in data;
};

const isSalesOrderLineResponseDto = (
  data: any
): data is SalesOrderLineResponseDto => {
  return typeof data === "object" && "assetParentId" in data;
};

const isInvoiceArLineResponseDto = (
  data: any
): data is TruckingInvoiceArLineResponseDto => {
  return typeof data === "object" && "cogsPrice" in data;
};

export class TruckingAccountReceivableMapper {
  static toInvoiceProductState(
    data: InvoiceArLineResponseDto
  ): TruckingAccountReceivableProductState;
  static toInvoiceProductState(
    data: SalesOrderLineResponseDto
  ): TruckingAccountReceivableProductState;
  static toInvoiceProductState(
    data: unknown
  ): TruckingAccountReceivableProductState {
    const state: TruckingAccountReceivableProductState =
      buildTruckingAccountReceivableProductState();

    if (isSalesOrderLineResponseDto(data)) {
      state.shipmentId = data.documentReference;
      if (data.assetId && data.unitCode) {
        state.unitCode = { key: data.assetId, label: data.unitCode };
      }
      state.serialNumber = data.serialNumber;
      state.equipment = data.productName;
      state.brand = data.merk;
      state.type = data.type;
      state.spec = data.specification;
      state.salesPerson = { key: data.salesName, label: data.salesName };
      state.price = data.price;
      state.description = data.description;
      state.salesOrderLineId = data.id;
      state.discountAmount = data.discountValue;
      state.discountPercent = data.percentDiscount;
      state.generated = true;
      state.taxRate = data.taxRate;
      state.taxCode = { label: data.taxCode, key: data.taxCodeId };
      TruckingAccountReceivableUtils.setDefaultRevenueAccount(state);
    }

    if (isInvoiceArLineResponseDto(data)) {
      state.docReference = data.documentReference;
      state.docReferenceId = data.documentReferenceId;
      state.shipmentId = data.shipmentId;
      state.id = data.id;
      if (data.unitCode && data.unitCodeId) {
        state.unitCode = { label: data.unitCode, key: data.unitCodeId };
      }
      state.serialNumber = data.serialNumber;
      state.equipment = data.equipment;
      state.brand = data.merk;
      state.type = data.type;
      state.spec = data.specification;
      state.salesPerson = { label: data.salesName, key: data.salesName };
      state.price = data.price;
      state.revenueAccount = {
        label: data.revenueAccount,
        key: data.revenueAccountId,
      };
      state.baseAmount = data.baseAmount;
      state.includePph = data.includePPh;
      if (data.incomeAccountTaxId) {
        state.incomeTax = {
          label: data.incomeAccountTax,
          key: data.incomeAccountTaxId,
        };
      }
      state.taxCode = { label: data.taxCode, key: data.taxId };
      state.taxAmount = data.taxValue;
      state.taxRate = data.taxRate;
      state.subtotal = data.subTotal;
      state.description = data.description;
      state.discountAmount = data.discountValue;
      state.discountPercent = data.percentDiscount;
      state.salesOrderLineId = data.salesOrderLineId;
      state.quantity = data.qty;
    }
    return state;
  }

  static toInvoiceProductsState(
    data: SalesOrderResponseDto
  ): TruckingAccountReceivableProductState[] {
    const state: TruckingAccountReceivableProductState[] = [];
    const hasOutstanding = data.salesOrderLines.filter(
      item => item.qtyOutstanding > 0
    );
    for (const line of hasOutstanding) {
      const product =
        TruckingAccountReceivableMapper.toInvoiceProductState(line);
      product.docReference = data.documentNumber;
      product.docReferenceId = data.id;
      state.push(product);
    }
    return state;
  }

  static toPrepaymentLineState(
    data: InvoicePrepaymentListResponseDto
  ): TruckingAccountReceivableFormPrepaymentLineState {
    const state: TruckingAccountReceivableFormPrepaymentLineState =
      buildTruckingAccountReceivableFormPrepaymentLineState();
    state.id = null;
    state.invoicePrepaymentId = data.id;
    state.invoicePrepaymentNumber = data.invoiceNumber;
    state.appliedAmount = data.grossAmount;
    state.description = data.description;
    return state;
  }

  static toFormStatusDetailInvoiceState(
    data: InvoiceArReceiptDetailDto
  ): TruckingAccountReceivableFormStatusDetailInvoiceState;
  static toFormStatusDetailInvoiceState(
    data: InvoiceArCreditLineResponseDto
  ): TruckingAccountReceivableFormStatusDetailInvoiceState;
  static toFormStatusDetailInvoiceState(
    data: InvoiceArPrepaymentLineResponseDto
  ): TruckingAccountReceivableFormStatusDetailInvoiceState;
  static toFormStatusDetailInvoiceState(
    data: unknown
  ): TruckingAccountReceivableFormStatusDetailInvoiceState {
    const state: TruckingAccountReceivableFormStatusDetailInvoiceState =
      buildTruckingAccountReceivableFormStatusDetailPrepaymentState();

    if (isPrepaymentLineResponseDto(data)) {
      state.amount = data.appliedAmount;
      state.invoiceDate = data.invoicePrepaymentDate;
      state.invoiceNumber = data.invoicePrepaymentNo;
    }

    if (isArCreditLineResponseDto(data)) {
      state.amount = data.creditAmount;
      state.invoiceDate = data.invoiceCreditDate;
      state.invoiceNumber = data.invoiceCreditNo;
    }

    if (isArReceiptDetailDto(data)) {
      state.amount = data.receiptAmount;
      state.invoiceDate = data.receiptDate;
      state.invoiceNumber = data.receiptNumber;
    }

    return state;
  }

  static toInvoiceArPrepaymentLineRequestDto(
    state: TruckingAccountReceivableFormPrepaymentLineState
  ): InvoiceArPrepaymentLineRequestDto {
    const dto: InvoiceArPrepaymentLineRequestDto =
      buildInvoiceArPrepaymentLineRequestDto();

    dto.id = state.id;
    dto.invoicePrepaymentId = state.invoicePrepaymentId;
    dto.appliedAmount = state.appliedAmount;
    dto.description = state.description ?? "";

    return dto;
  }

  static toInvoiceArLineRequestDto(
    state: TruckingAccountReceivableProductState
  ): TruckingInvoiceArLineRequestDto {
    const dto: TruckingInvoiceArLineRequestDto =
      buildTruckingInvoiceArLineRequestDto();
    dto.id = state.id ?? "";
    dto.assetId = state.unitCode?.key ?? "";
    dto.salesOrderLineId = state.salesOrderLineId ?? "";
    dto.description = state.description;
    dto.qty = state.quantity;
    dto.price = state.price;
    dto.revenueAccountId = state.revenueAccount?.key ?? "";
    dto.baseAmount = state.baseAmount;
    dto.taxId = state.taxCode?.key ?? "";
    dto.taxValue = state.taxAmount;
    dto.subTotal = state.subtotal;
    dto.percentDiscount = state.discountPercent;
    dto.discountValue = state.discountAmount;
    dto.incomeAccountTaxId = state.incomeTax?.key ?? "";
    dto.includePPh = state.includePph;
    dto.salesName = state.salesPerson?.label ?? "";
    return dto;
  }

  static toInvoiceArUpdateDto(
    state: TruckingAccountReceivableFormState
  ): TruckingInvoiceArUpdateDto {
    const dto: TruckingInvoiceArUpdateDto = buildTruckingInvoiceArUpdateDto();
    dto.customerGoodReceiptNo = state.customerGoodReceiptNo;
    dto.customerPurchaseOrderNo = state.customerPurchaseOrderNo;
    dto.invoiceSource = state.invoiceSource;
    dto.salesOrderIds = state.salesOrders.map(item => item.key);
    dto.branchWarehouseId = state.branch?.key ?? "";
    dto.customerId = state.customer?.key ?? "";
    dto.customerShipToAddress = state.shippingAddress ?? "";
    dto.customerBillToAddress = state.billingAddress ?? "";
    dto.taxType = state.taxCalculation ?? TAX_CALCULATION.EXCLUSIVE;
    dto.termOfPayment = state.termOfPayment ?? 0;
    dto.invoiceDate = state.invoiceDate?.format() ?? "";
    dto.accountingDate = state.accountingDate?.format() ?? "";
    dto.currency = state.currency?.label ?? "";
    dto.currencyRate = state.currencyRates;
    dto.receivableAccountId = state.receivableAccount?.key ?? "";
    dto.operatorName = state.operatorName ?? "";
    dto.description = state.description;
    dto.customerTaxType = state.customerTaxType;
    dto.taxRegistrationNumber = state.taxRegistrationNumber;
    dto.taxRegistrationName = state.taxRegistrationName;
    dto.taxInvoiceDate = state.taxInvoiceDate?.format() ?? "";
    dto.taxInvoiceNumber = state.taxInvoiceNumber;
    dto.taxIsUploaded = state.taxIsUploaded;
    dto.discountValue = state.discountAmount;
    dto.percentDiscount = state.discountPercent;
    dto.assignedBy = state.assignee?.label ?? "";
    dto.applyPrepayment.prepaymentLines = state.prepayment.prepaymentLines.map(
      TruckingAccountReceivableMapper.toInvoiceArPrepaymentLineRequestDto
    );
    dto.applyPrepayment.deletedPrepaymentLineIds =
      state.prepayment.deletedPrepaymentLineIds;
    dto.invoiceARLines = state.products.map(
      TruckingAccountReceivableMapper.toInvoiceArLineRequestDto
    );

    for (const item of state.deletedInvoiceARLines) {
      if (item.id) {
        dto.deletedInvoiceARLineIds.push(item.id);
      }
    }

    return dto;
  }

  static toInvoiceArCreateDto(
    state: TruckingAccountReceivableFormState
  ): TruckingInvoiceArCreateDto {
    const dto: TruckingInvoiceArCreateDto = buildTruckingInvoiceArCreateDto();
    const TRUCKING_SOURCE = "Trucking";
    dto.invoiceType = state.invoiceType;
    dto.invoiceSource = TRUCKING_SOURCE;
    dto.salesOrderIds = state.salesOrders.map(item => item.key);
    dto.branchWarehouseId = state.branch?.key ?? "";
    dto.customerId = state.customer?.key ?? "";
    dto.customerShipToAddress = state.shippingAddress ?? "";
    dto.customerBillToAddress = state.billingAddress ?? "";
    dto.taxType = state.taxCalculation ?? TAX_CALCULATION.EXCLUSIVE;
    dto.termOfPayment = state.termOfPayment ?? 0;
    dto.invoiceDate = state.invoiceDate?.format() ?? "";
    dto.accountingDate = state.accountingDate?.format() ?? "";
    dto.currency = state.currency?.label ?? "";
    dto.currencyRate = state.currencyRates;
    dto.receivableAccountId = state.receivableAccount?.key ?? "";
    dto.operatorName = state.operatorName ?? "";
    dto.description = state.description;
    dto.customerTaxType = state.customerTaxType;
    dto.taxRegistrationNumber = state.taxRegistrationNumber;
    dto.taxRegistrationName = state.taxRegistrationName;
    dto.taxInvoiceDate = state.taxInvoiceDate?.format() ?? "";
    dto.taxInvoiceNumber = state.taxInvoiceNumber;
    dto.taxIsUploaded = state.taxIsUploaded;
    dto.discountValue = state.discountAmount;
    dto.percentDiscount = state.discountPercent;
    dto.applyPrepayment.prepaymentLines = state.prepayment.prepaymentLines.map(
      TruckingAccountReceivableMapper.toInvoiceArPrepaymentLineRequestDto
    );
    dto.applyPrepayment.deletedPrepaymentLineIds =
      state.prepayment.deletedPrepaymentLineIds;
    dto.invoiceARLines = state.products.map(
      TruckingAccountReceivableMapper.toInvoiceArLineRequestDto
    );
    dto.assignedBy = state.assignee?.label ?? "";
    dto.customerGoodReceiptNo = state.customerGoodReceiptNo;
    dto.customerPurchaseOrderNo = state.customerPurchaseOrderNo;

    return dto;
  }

  static toTruckingAccountReceivableFormPrepaymentState(
    data: InvoiceArPrepaymentResponseDto
  ): TruckingAccountReceivableFormPrepaymentState {
    const state: TruckingAccountReceivableFormPrepaymentState =
      buildTruckingAccountReceivableFormPrepaymentState();
    state.prepaymentLines = data.prepaymentLines.map(item => ({
      appliedAmount: item.appliedAmount,
      description: item.description,
      id: item.id,
      invoicePrepaymentId: item.invoicePrepaymentId,
      invoicePrepaymentNumber: item.invoicePrepaymentNo,
    }));
    return state;
  }

  static toTruckingAccountReceivableFormStatusDetailState(
    data: InvoiceArResponseDto
  ): TruckingAccountReceivableFormStatusDetailState {
    const state: TruckingAccountReceivableFormStatusDetailState =
      buildTruckingAccountReceivableFormStatusDetailState();
    state.total = data.dpp;
    state.prepaymentUsed = data.prepaymentUsed;
    state.prepaymentLines = data.applyPrepayment.prepaymentLines.map(item => ({
      amount: item.appliedAmount,
      invoiceDate: item.invoicePrepaymentDate,
      invoiceNumber: item.invoicePrepaymentNo,
      key: generateUUID(),
    }));
    state.remainingInvoiceAmount = data.remainingInvoiceAmount;
    state.creditMemoLines = data.applyCredit.map(item => ({
      amount: item.creditAmount,
      invoiceDate: item.invoiceCreditDate,
      invoiceNumber: item.invoiceCreditNo,
      key: generateUUID(),
    }));
    state.creditMemoUsed = data.creditMemoUsed;
    state.returnAmount = data.returnAmount;
    state.receivableAccount = data.receivableAccount;
    state.invoiceJoinNo = data.invoiceJoinNo;
    state.invoiceReceipts = data.invoiceARReceiptDetails.map(item => ({
      amount: item.receiptAmount,
      invoiceDate: item.receiptDate,
      invoiceNumber: item.receiptNumber,
      key: generateUUID(),
    }));
    return state;
  }

  static toFormState(
    data: TruckingInvoiceArResponseDto
  ): TruckingAccountReceivableFormState {
    const state: TruckingAccountReceivableFormState =
      buildTruckingAccountReceivableFormState();
    state.invoiceType = data.invoiceType;
    state.invoiceSource = data.invoiceSource;
    state.invoiceDate = moment(data.invoiceDate);
    state.branch = {
      label: data.branchWarehouseName,
      key: data.branchWarehouseId,
    };
    state.currency = { label: data.currency, key: data.currency };
    state.currencyRates = data.currencyRate;
    state.customer = {
      label: `${data.customerName} (${data.custCode})`,
      key: data.customerId,
    };
    state.shippingAddress = data.customerShipToAddress;
    state.billingAddress = data.customerBillToAddress;
    state.salesOrders = data.salesOrders.map(item => ({
      label: item.documentNumber,
      key: item.id,
    }));
    state.invoiceNumber = data.documentNumber;
    state.taxCalculation = data.taxType;
    state.termOfPayment = data.termOfPayment;
    state.receivableAccount = {
      key: data.receivableAccountId,
      label: data.receivableAccount,
    };
    state.operatorName = data.operatorName;
    state.assignee = { key: data.assignedBy, label: data.assignedBy };
    state.description = data.description;
    state.status = data.status;
    state.customerTaxType = data.customerTaxType;
    state.taxRegistrationName = data.taxRegistrationName;
    state.taxRegistrationNumber = data.taxRegistrationNumber;
    state.taxIsUploaded = data.taxIsUploaded;
    state.taxInvoiceDate = moment(data.taxInvoiceDate);
    state.taxInvoiceNumber = data.taxInvoiceNumber;
    state.prepayment =
      TruckingAccountReceivableMapper.toTruckingAccountReceivableFormPrepaymentState(
        data.applyPrepayment
      );
    state.statusDetail =
      TruckingAccountReceivableMapper.toTruckingAccountReceivableFormStatusDetailState(
        data
      );
    state.products = data.invoiceARLines.map(
      TruckingAccountReceivableMapper.toInvoiceProductState
    );
    state.subTotal = data.dpp;
    state.discountAmount = data.discountValue;
    state.discountPercent = data.percentDiscount;
    state.totalPrepayment = data.prepaymentUsed;
    state.grandTotal = data.grandTotal;
    state.accountingDate = moment(data.accountingDate);
    state.journalId = data.journalId;
    state.journalNumber = data.journalNo;
    state.customerGoodReceiptNo = data.customerGoodReceiptNo;
    state.customerPurchaseOrderNo = data.customerPurchaseOrderNo;
    return state;
  }
}
